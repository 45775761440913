import { useEffect } from "react";
import { useCustomImageStore } from "@entities/customImage";
import { CircleProgress } from "@shared/ui/CircleProgress";
import { ReactComponent as ImagePlaceholder } from "@shared/assets/image_placeholder.svg";
import { useCountdown } from "@shared/hooks";
import styles from "./GenerateImageResult.module.css";

const ESTIMATED_TIME = 60;

type Props = {
  subText?: string;
};

const GenerateImageResult = ({ subText }: Props) => {
  const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
    countStart: ESTIMATED_TIME,
    intervalMs: 500,
  });
  const { data, isGenerating, isGenerated } = useCustomImageStore();
  const percentage = ((ESTIMATED_TIME - count) / ESTIMATED_TIME) * 100;

  useEffect(() => {
    if (isGenerating) {
      resetCountdown();
      startCountdown();
    }

    if (!isGenerating && isGenerated) {
      stopCountdown();
    }
  }, [isGenerated, isGenerating, resetCountdown, startCountdown, stopCountdown]);

  return (
    <div className={styles.result}>
      {isGenerating && (
        <div className={styles.progress}>
          <div className={styles.progress__loader}>
            <CircleProgress percentage={percentage >= 99 ? 99 : percentage} />
          </div>
          <span className={styles.progress__title}>Please wait!</span>
          <p className={styles.progress__subtitle}>{subText}</p>
        </div>
      )}

      {!isGenerating && !isGenerated && (
        <div className={styles.placeholder}>
          <ImagePlaceholder />
          <span className={styles.placeholder__label}>Enter a prompt and click "Generate"</span>
        </div>
      )}

      {isGenerated && (
        <div className={styles.result__image}>
          <img src={data[0]} alt={subText} />
        </div>
      )}
    </div>
  );
};

export default GenerateImageResult;
