import styles from "./styles.module.css";
import { ROUTES } from "@shared/interfaces";
import CloseIcon from "@assets/close_gray.svg";
import UpdatePlanImg from "@assets/update-plan.jpg";
import Token from "@assets/token.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonFactory } from "@shared/ui/Button";
import * as amplitude from "@amplitude/analytics-browser";
import { useEffect } from "react";

interface IProps {
  onClose?: () => void;
  source: string;
  action: string;
}

const BuyTokens = ({ onClose, source, action }: IProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    amplitude.track(`Token Depletion Pop-Up Shown`, {
      actionAttempted: action,
      source: source,
    });
  }, []);

  const toPayToken = () => {
    amplitude.track(`Get Tokens Button Clicked`);

    localStorage.setItem("returnUrl", pathname);
    navigate(`${ROUTES.SUBSCRIBE}?source=Get Tokens Popup`);
  };

  const onClickClose = () => {
    amplitude.track(`Token Depletion Pop-Up Closed`);
    onClose && onClose();
  };

  return (
    <div className={styles.buy_tokens_base}>
      <div className={styles.buy_tokens_container}>
        <div className={styles.buy_tokens_bg}>
          <img src={UpdatePlanImg} alt="buy_tokens" />
        </div>
        <div className={styles.buy_tokens_content}>
          <img src={CloseIcon} className={styles.buy_close_button} alt="close" onClick={onClickClose} />

          <div className={styles.bg_token_img}>
            <img src={Token} alt="token" />
          </div>
          <h2>Oops! Looks like we're out of tokens {String.fromCodePoint(parseInt("0x1F605", 16))}</h2>
          <span className={styles.buy_tokens_suptitle}>
            Top Up Now, And I'll Get Back To Creating Amazing Images For You!
          </span>

          <ButtonFactory label="Get Tokens" onClick={toPayToken} variant="danger" />
        </div>
      </div>
    </div>
  );
};

export default BuyTokens;
